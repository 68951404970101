import React from 'react';
import Card from '../Card/Card';
import CurrentUser from '../user/CurrentUser';
import formatDate from '.././../functions/formatDate';
import formatTime from '.././../functions/formatTime';
import getUserInfo from '.././../functions/getUserInfo';
import './BulletinCard.scss';

const BulletinCard = (props) => {
  const { _id, associetedTour, author, title, content, createdAt } = props;

  if (!_id) return 'No content.';

  return (
    <Card className='bulletin-card' key={_id}>
      <article className='bulletin-card__container'>
        <header className='bulletin-card__header'>
          <div className='bulletin-card__avatar'>
            <CurrentUser />
          </div>
          <div className='bulletin-card__info'>
            <h3 className='bulletin-card__title'>{title}</h3>
            <span className='bulletin-card__author bulletin-card__item'>
              {getUserInfo(author, 'fullname')}
            </span>
            <span className='bulletin-card__date bulletin-card__item'>
              {formatDate(createdAt)}
            </span>
            <span className='bulletin-card__time bulletin-card__item'>
              {formatTime(createdAt)}
            </span>
          </div>
        </header>
        <div className='bulletin-card__content'>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </article>
    </Card>
  );
};
export default BulletinCard;
