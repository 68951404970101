import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Tours from '../../routes/Tours';
import NoRouteFound from '../../routes/NoRouteFound';
import Dashboard from '../../routes/Dashboard';
import Header from '../Header/Header';
import CurrentUser from '../user/CurrentUser';
import Icon from '../Icon/Icon';
import './Sidebar.scss';
import './Main.scss';

const routes = {
  landing: '/',
  tours: '/tours',
  dashboard: '/dashboard',
  tour: '/tour/:title',
  test: '/test'
};

const Nav = props => {
  return (
    <Router>
      <div className='page-wrapper'>
        <Header />
        <nav className='nav nav--main e-shadow'>
          <div className='nav__items'>
            <Link className='nav__item nav__item--active' to='/dashboard'>
              <Icon type='dashboard' className='nav__item-icon' />
            </Link>
            <Link className='nav__item' to='/tours'>
              <Icon type='tours' className='nav__item-icon' />
            </Link>
            <Link className='nav__item' to='/chat'>
              <Icon type='chat' className='nav__item-icon' />
            </Link>
            <Link to='/settings' className='nav__item nav__item--settings'>
              <Icon type='settings' className='nav__item-icon' />
            </Link>
          </div>
        </nav>
        <nav className='nav nav--user'>
          <div className='nav__items'>
            <Link to='/user' className='nav__item nav__item--avatar'>
              <CurrentUser />
            </Link>
          </div>
        </nav>
        <main className='main'>
          <Switch>
            <Route path={routes.tours} component={Tours} />
            <Route path={routes.dashboard} component={Dashboard} />
            <Route component={NoRouteFound} />
          </Switch>
        </main>
      </div>
    </Router>
  );
};
export default Nav;
