import React from 'react';

const CurrentUserView = props => {
  const image = props.image.standard;
  const imageSrc = image
    ? image
    : 'https://images.unsplash.com/photo-1514888286974-6c03e2ca1dba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60';
  return <img src={imageSrc} alt='' className='c-avatar' />;
};

export default CurrentUserView;
