import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_CHAT_CHANNEL_MUTATION } from '../../Mutations';

// This component needs to live inside of a single tour so it may obtain th associated tour ID.
// Each tour can have it's own chat channels assigned to it's ID

const CreateChatChannel = props => {
  const [chatChannelName, setChatChannelName] = useState('Chat Channel Name');
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [createChatChannel] = useMutation(CREATE_CHAT_CHANNEL_MUTATION);
  return (
    <>
      <h2>Create Chat Channel</h2>
      <input
        value={chatChannelName || ''}
        placeholder="Set A Channel Name"
        type="text"
        id="CreateChatChannel"
        onChange={e => setChatChannelName(e.target.value)}
      />

      <br />

      <h2>Include search search here</h2>

      <br />
      <button
        onClick={e =>
          handleCreateChatChannel(
            chatChannelName,
            createChatChannel,
            membersToAdd,
            props
          )
        }
      >
        Add Channel
      </button>
    </>
  );
};

const handleCreateChatChannel = async (
  chatChannelName,
  createChatChannel,
  membersToAdd,
  props
) => {
  await createChatChannel({
    variables: {
      input: {
        associatedTour: '5e0e0674dce8ec619335516c',
        title: chatChannelName,
        members: membersToAdd,
        messages: []
      }
    }
  });
  console.log('channel created successfully');
};
export default CreateChatChannel;
