import React, { useContext } from 'react';
import { Skeleton } from 'antd';
import CurrentUserContext from '../../context/CurrentUserContext';
import CurrentUserView from './CurrentUserView';

const CurrentUser = props => {
  const currentUserContext = useContext(CurrentUserContext);

  return currentUserContext.currentUser !== null ? (
    <CurrentUserView {...currentUserContext.currentUser} />
  ) : (
    <Skeleton active />
  );
};

export default CurrentUser;
