import React from 'react';
import Chat from '../components/chat/Chat';

const SingleTour = props => {
  const {
    state: { _id, title }
  } = props.location;
  return (
    <>
      <h2>{title}</h2>
      <Chat id={_id} />
    </>
  );
};
export default SingleTour;
