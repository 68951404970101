import React from 'react';
import './Logo.scss';

const Logo = (props) => {
  const { width } = props;

  return (
    <div className='logo'>
      <img
        src='assets/images/logo.png'
        alt='Daysheet logo'
        className={`logo__image logo__image--${width}`}
      />
      <h1 className='logo__title'>Daysheet</h1>
    </div>
  );
};
export default Logo;
