import React from 'react';

// TODO: Add the images to DB
const backgroundImages = [
  'https://images.unsplash.com/photo-1526478806334-5fd488fcaabc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2890&q=80',
  'https://images.unsplash.com/photo-1507245921392-e902673ca772?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80',
  'https://images.unsplash.com/photo-1501612780327-45045538702b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80',
];

const SignInBackground = () => {
  const image =
    backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
  return (
    <div className='page-signin__background'>
      <img
        src={image}
        alt='background'
        className='page-signin__background-image'
      />
    </div>
  );
};
export default SignInBackground;
