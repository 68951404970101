import React from 'react';
import Logo from '../Logo/Logo';
import './Header.scss';

const Header = (props) => {
  return (
    <header className='page-header'>
      <Logo />
    </header>
  );
};
export default Header;
