import React from 'react';
import './SignInUserView.scss';
import Logo from '../../components/Logo/Logo';
import SignInBackground from './SignInBackground';
import SignInFormRender from './SignInForm';

const UserSignInView = (props) => {
  return (
    <section className='page-signin'>
      <div className='page-signin__info'>
        <Logo width={'big'} />
        <SignInFormRender {...props} />
      </div>
      <SignInBackground {...props} />
    </section>
  );
};

export default UserSignInView;
