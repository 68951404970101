import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CurrentUserContext from '../context/CurrentUserContext';

import { SIGNIN_MUTATION } from '../Mutations';
import { GET_CURRENT_USER_QUERY } from '../Queries';
import SignInUserView from '../views/SignInUser/SignInUserView';

const HandleUserSignIn = async (values, props) => {
  const { signin, refetch } = props.propData;
  await signin({
    variables: { ...values },
  });
  refetch();
};
const UserSignIn = () => {
  const [signin] = useMutation(SIGNIN_MUTATION);
  const { loading, error, data, refetch } = useQuery(GET_CURRENT_USER_QUERY);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const propData = {
    signin,
    loading,
    data,
    error,
    refetch,
    email,
    setEmail,
    password,
    setPassword,
    HandleUserSignIn,
  };

  return <SignInUserView propData={propData} />;
};
export default UserSignIn;
