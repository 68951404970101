import React, { useContext } from 'react';
import { Skeleton } from 'antd';
import CurrentUserContext from '../context/CurrentUserContext';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER_QUERY } from '../Queries';
import Dashboard from './Dashboard';
import SignInUser from '../controllers/SignInUser';

const Wrapper = props => {
  const currentUserContext = useContext(CurrentUserContext);

  const { loading, error, data } = useQuery(GET_CURRENT_USER_QUERY);

  if (!loading && !error) {
    currentUserContext.setCurrentUser(
      data.getCurrentUser !== null ? data.getCurrentUser : null
    );
  }

  return (
    <>
      {loading && <Skeleton active />}
      {currentUserContext.currentUser !== null ? (
        <Dashboard user={currentUserContext.currentUser} />
      ) : (
        <SignInUser />
      )}
    </>
  );
};

export default Wrapper;
