import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_UPCOMING_TOURS_QUERY } from '../Queries';
import TourNav from '../routes/TourNav';

const Tours = () => {
  const { loading, error, data } = useQuery(GET_UPCOMING_TOURS_QUERY);
  if (loading) return 'loading';
  if (error) return 'error';
  return <TourNav {...data} />;
};

export default Tours;
