import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useCurrentUser from '../../customHooks/useCurrentUser';
import CreateChatChannel from '../chat/CreateChatChannel';
import ChatLog from '../chat/ChatLog';
import { CREATE_CHAT_MESSAGE_MUTATION } from '../../Mutations';
import { GET_ALL_CHAT_CHANNELS_QUERY } from '../../Queries';

const Chat = props => {
  const [message, setMessage] = useState('');
  const { currentUser } = useCurrentUser();
  const { loading, error, data } = useQuery(GET_ALL_CHAT_CHANNELS_QUERY, {
    variables: { associatedTour: props.id }
  });
  const [createChatMessage] = useMutation(CREATE_CHAT_MESSAGE_MUTATION);
  if (loading) return 'loading';
  if (error) return 'error';

  const blockProps = {
    associatedChatChannelId: data.getChatChannels[0]._id,
    currentUser,
    createChatMessage,
    message,
    setMessage,
    ...props
  };
  return (
    <>
      <h2>Chat</h2>
      <CreateChatChannel />
      <ChatLog associatedChatChannelId={data.getChatChannels[0]._id} />
      <div>
        <input
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <button onClick={() => submitMessage(blockProps)}>Send</button>
      </div>
    </>
  );
};
const submitMessage = async blockProps => {
  const {
    createChatMessage,
    message,
    associatedChatChannelId,
    currentUser,
    setMessage
  } = blockProps;

  await createChatMessage({
    variables: {
      input: {
        associatedChat: associatedChatChannelId,
        from: currentUser._id,
        content: message
        // createdAt: Date.now(),
        // updatedAt: Date.now()
      }
    }
  });
  setMessage('');
};

export default Chat;
