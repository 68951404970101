import React from 'react';
import Card from '../Card/Card';
import './TodayCard.scss';

const TodayCard = (props) => {
  const { id, title, date, location, events } = props;

  // Fallback
  if (!id) return <p>No content.</p>;

  const Content = (props) => {
    return (
      <ul className='today-card__events'>
        {events.map((event) => {
          return (
            <li className='today-card__event'>
              <span className='today-card__time'>{event.time}</span>
              <span className='today-card__description'>
                {event.description}
              </span>
              <span className='today-card__place'>{event.place}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Card key={id}>
      <div className='today-card__container'>
        <div className='c-card__header'>
          <p className='c-card__date'>{date}</p>
          <h2 className='c-card__title'>{title}</h2>
          <p className='c-card__location'>{location}</p>
        </div>
        <div className='c-card__content'>
          <Content />
        </div>
      </div>
    </Card>
  );
};
export default TodayCard;
