import gql from 'graphql-tag';

export const CHAT_MESSAGE_ADDED = gql`
  subscription CHAT_MESSAGE_ADDED {
    chatMessageAdded {
      _id
      content
    }
  }
`;
