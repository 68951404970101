import React, { useState } from 'react';
import './styles/global.scss';
import CurrentUserContext from './context/CurrentUserContext';
import client from './ApolloClient';
import { ApolloProvider } from 'react-apollo';
import Wrapper from './components/Wrapper';

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const currentUserContext = {
    currentUser,
    setCurrentUser
  };

  return (
    <ApolloProvider client={client}>
      <CurrentUserContext.Provider value={currentUserContext}>
        <Wrapper />
        {/* <Nav /> */}
      </CurrentUserContext.Provider>
    </ApolloProvider>
  );
};

export default App;
