import gql from "graphql-tag";

export const GET_CURRENT_USER_QUERY = gql`
  query {
    getCurrentUser {
      _id
      email
      firstName
      lastName
      image {
        publicId
        standard
        large
      }
    }
  }
`;

export const GET_UPCOMING_TOURS_QUERY = gql`
  query GET_UPCOMING_TOURS_QUERY {
    getUpcomingTours {
      _id
      title
      startDate
      endDate
      dates
      image {
        publicId
        standard
        large
      }
    }
  }
`;

export const GET_ALL_DAYS_QUERY = gql`
  query GET_ALL_DAYS($associatedTour: ID!) {
    getAllDays(associatedTour: $associatedTour) {
      _id
      date
      venue {
        venueName
      }
      location {
        city
        country
      }
    }
  }
`;
export const GET_SINGLE_DAY_QUERY = gql`
  query GET_SINGLE_DAY_QUERY($id: ID!) {
    getSingleDay(_id: $id) {
      _id
      associatedTour
      date
      promoter {
        name
        phone
        email
      }
      advanceContact {
        name
        phone
        email
      }
      hospitality {
        name
        phone
        email
      }
      runner {
        name
        phone
        email
      }
      merchandise {
        name
        phone
        email
      }
      settlement {
        name
        phone
        email
      }
      location {
        city
        region
        country
        mapUrl
      }
      parking {
        image
        details
      }
      internet {
        login
        password
      }
      merchRate
      ticketPrice
      showers
      laundry
      stage {
        clearence
        dimensions
      }
      dressingRoom
      venue {
        capacity
        venueName
        venueAccess
        venueWebsite
        venueAddress
        venuePhoneNumber
      }
      loadIn
      catering {
        location
        breakfast
        lunch
        dinner
      }
      soundcheck {
        band
        time
        duration
      }
      setTime {
        band
        time
        duration
        changeOverDuration
      }
      doors
      press
      curfew
      busCall
      guestList
      extraInfo
      nextDay {
        location
        details
        distance
        venueAccess
      }
    }
  }
`;
export const GET_NOTIFICATIONS_QUERY = gql`
  query GET_NOTIFICATIONS_QUERY {
    getAllNotifications {
      _id
      notificationType
      message
      associatedTour
    }
  }
`;
export const GET_ALL_PENDING_USER_REQUEST_NOTIFICATIONS_QUERY = gql`
  query GET_ALL_PENDING_USER_REQUEST_NOTIFICATIONS_QUERY($associatedTour: ID!) {
    getAllPendingUserRequestNotifications(associatedTour: $associatedTour) {
      _id
      status
      to {
        firstName
        lastName
        email
      }
    }
  }
`;

export const SINGLE_TOUR_QUERY = gql`
  query SINGLE_TOUR_QUERY($id: ID!) {
    getSingleTour(_id: $id) {
      _id
      title
      image {
        publicId
        standard
        large
      }
      startDate
      endDate
      members {
        _id
        email
      }
      author {
        _id
      }
    }
  }
`;

export const SEARCH_USERS_QUERY = gql`
  query SEARCH_USERS_QUERY($name: String) {
    searchUsers(name: $name) {
      _id
      firstName
      lastName
    }
  }
`;
export const GET_ROLE_QUERY = gql`
  query GET_ROLE_QUERY($tourId: ID!, $userId: ID!) {
    getRole(tourId: $tourId, userId: $userId) {
      _id
      role
    }
  }
`;
export const GET_ALL_PERMISSIONS_QUERY = gql`
  query GET_ALL_PERMISSIONS {
    __type(name: "Permission") {
      enumValues {
        name
      }
    }
  }
`;
export const GET_ALL_GROUPS_QUERY = gql`
  query GET_ALL_GROUPS {
    __type(name: "GroupType") {
      enumValues {
        name
      }
    }
  }
`;
export const GET_ALL_CHAT_CHANNELS_QUERY = gql`
  query GET_ALL_CHAT_CHANNELS_QUERY($associatedTour: ID!) {
    getChatChannels(associatedTour: $associatedTour) {
      title
      _id
    }
  }
`;
export const GET_ALL_CHAT_CHANNEL_MESSAGES_QUERY = gql`
  query GET_ALL_CHAT_CHANNEL_MESSAGES_QUERY($chatChannelId: ID!) {
    getChatChannelMessages(chatChannelId: $chatChannelId) {
      _id
      content
    }
  }
`;
