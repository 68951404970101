import React from 'react';
import './Dashboard.scss';
import Card from '../components/Card/Card';
import TodayCard from '../components/TodayCard/TodayCard';
import BulletinCard from '../components/BulletinCard/BulletinCard';
import data from '../data/data.json'; // FAKE DATA

const Dashboard = (props) => {
  return (
    <section className='dashboard'>
      <article className='dashboard__today'>
        <header>
          <h2>Today</h2>
          {data.today.map((day, index) => {
            return <TodayCard {...day} key={index} />;
          })}
        </header>
      </article>
      <article className='dashboard__bulletin'>
        <h2>Bulletin</h2>
        {data.bulletins.map((bulletin, index) => {
          return <BulletinCard {...bulletin} key={index} />;
        })}
      </article>
      <article className='dashboard__highlights'>
        <Card className='c-card--figure'>
          <div className='c-card__header'>
            <img
              className='c-card__image'
              alt='by sergio souza on unplash'
              src='https://images.unsplash.com/photo-1536082555308-99948d5c8ecf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2467&q=80'
            />
          </div>
          <div className='c-card__content'>
            <p>Current Tour</p>
            <h3>Brazil 2020</h3>
          </div>
        </Card>

        <Card className='c-card--number'>
          <div className='c-card__header'>
            <h3 className='c-card__title'>15</h3>
          </div>
          <div className='c-card__content'>
            <p>Days on tour</p>
          </div>
          <span className='c-card__indicator background--orange' />
        </Card>
        <Card className='c-card--number'>
          <div className='c-card__header'>
            <h3 className='c-card__title'>736</h3>
          </div>
          <div className='c-card__content'>
            <p>Miles Traveled</p>
          </div>
          <span className='c-card__indicator background--white' />
        </Card>
        <Card className='c-card--number'>
          <div className='c-card__header'>
            <h3 className='c-card__title'>18</h3>
          </div>
          <div className='c-card__content'>
            <p>Cities Visited</p>
          </div>
          <span className='c-card__indicator background--brown' />
        </Card>
      </article>
    </section>
  );
};

export default Dashboard;
