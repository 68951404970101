import data from '../data/data.json';

const getUserInfo = (userId, option) => {
  const user = data.users.find((user) => user._id === parseInt(userId));
  const userInfo = {
    fullname: user.firstName + ' ' + user.lastName,
    firstname: user.firstName,
    lastname: user.lastName,
    email: user.email,
  };

  return userInfo[option];
};
export default getUserInfo;
