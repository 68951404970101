import React from 'react';
import { Form, Input, Button } from 'antd';

const SignInFormRender = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    HandleUserSignIn,
  } = props.propData;

  return (
    <Form
      className='page-signin-form'
      method='post'
      // onSubmit={async e => HandleUserSignIn(e, props)}
      onFinish={(values) => HandleUserSignIn(values, props)}
    >
      <Form.Item
        label='Email'
        name='email'
        className='page-signin-form__item'
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input
          className='page-signin-form__input'
          htmlType='email'
          name='email'
          value={email}
          placeholder={'e-mail'}

          // onChange={e => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please input your password!' }]}
        className='page-signin-form__item'
      >
        <Input.Password
          className='page-signin-form__input'
          htmlType='password'
          name='password'
          value={password}
          // onChange={e => setPassword(e.target.value)}
          placeholder={'password'}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          className='button button--primary'
        >
          Sign In
        </Button>
      </Form.Item>
      <div className='page-signin-form__recover'>
        <p>
          <a href='#'>Do you need an account?</a>
        </p>
        <p>
          <a href='#'>Forgot your password?</a>
        </p>
      </div>
    </Form>
  );
};
export default SignInFormRender;
