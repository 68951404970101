import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CHAT_MESSAGE_ADDED } from '../../Subscriptions';
import { GET_ALL_CHAT_CHANNEL_MESSAGES_QUERY } from '../../Queries';

const ChatLog = props => {
  const [messages, setMessages] = useState([]);
  const { loading, error, subscribeToMore } = useQuery(
    GET_ALL_CHAT_CHANNEL_MESSAGES_QUERY,
    {
      variables: { chatChannelId: props.associatedChatChannelId }
    }
  );
  useEffect(() => {
    subscribeToMore({
      document: CHAT_MESSAGE_ADDED,
      variables: { chatChannelId: props.associatedChatChannelId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.chatMessageAdded;
        setMessages([...messages, newMessage]);
      }
    });
  }, [props.associatedChatChannelId, subscribeToMore, messages]);

  return <Messages messages={messages} loading={loading} error={error} />;
};
const Messages = props => {
  const { messages, loading, error } = props;
  if (loading) return 'loading';
  if (error) return 'error';
  return messages.map(message => <li key={message._id}>{message.content}</li>);
};
export default ChatLog;
