import gql from "graphql-tag";

export const REQUEST_ADD_USER_MUTATION = gql`
  mutation REQUEST_ADD_USER_MUTATION($userId: ID!, $tourId: ID!) {
    requestAddUser(userId: $userId, tourId: $tourId) {
      _id
    }
  }
`;
export const DELETE_TOUR_MUTATION = gql`
  mutation DELETE_TOUR_MUTATION($tourId: ID!) {
    deleteTour(tourId: $tourId) {
      _id
    }
  }
`;

export const CREATE_TOUR_MUTATION = gql`
  mutation CREATE_TOUR_MUTATION($input: TourInput!) {
    createTour(input: $input) {
      _id
      title
    }
  }
`;
export const CREATE_CHAT_CHANNEL_MUTATION = gql`
  mutation CREATE_CHAT_CHANNEL_MUTATION($input: ChatChannelInput!) {
    createChatChannel(input: $input) {
      _id
    }
  }
`;
export const UPDATE_CHAT_CHANNEL_MUTATION = gql`
  mutation UPDATE_CHAT_CHANNEL_MUTATION($input: ChatChannelInput!) {
    updateChatChannel(input: $input) {
      _id
    }
  }
`;
export const DELETE_CHAT_CHANNEL_MUTATION = gql`
  mutation DELETE_CHAT_CHANNEL_MUTATION($id: ID!) {
    createTour(id: $id) {
      _id
    }
  }
`;
export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation CREATE_CHAT_MESSAGE_MUTATION($input: ChatMessageInput!) {
    createChatMessage(input: $input) {
      _id
    }
  }
`;
export const UPDATE_CHAT_MESSAGE_MUTATION = gql`
  mutation UPDATE_CHAT_MESSAGE_MUTATION($input: ChatMESSAGEInput!) {
    updateChatMessage(input: $input) {
      _id
    }
  }
`;
export const DELETE_CHAT_MESSAGE_MUTATION = gql`
  mutation DELETE_CHAT_MESSAGE_MUTATION($id: ID!) {
    createTour(id: $id) {
      _id
    }
  }
`;

export const UPDATE_TOUR_MUTATION = gql`
  mutation UPDATE_TOUR_MUTATION($input: TourInput!) {
    updateTour(input: $input) {
      _id
      title
      startDate
      endDate
      datesToAdd
      image {
        publicId
        standard
        large
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION($input: UpdateUserInput!) {
    updateUser(input: $input) {
      _id
      firstName
      lastName
      email
      image {
        publicId
        standard
        large
      }
    }
  }
`;

export const NOTIFICATION_RESPONSE_MUTATION = gql`
  mutation NOTIFICATION_RESPONSE_MUTATION(
    $response: Int!
    $associatedTour: ID!
    $notificationId: ID!
  ) {
    notificationReponse(
      response: $response
      associatedTour: $associatedTour
      notificationId: $notificationId
    ) {
      _id
    }
  }
`;
export const REMOVE_NOTIFICATION_MUTATION = gql`
  mutation REMOVE_NOTIFICATION_MUTATION($notificationId: ID!) {
    removeNotification(notificationId: $notificationId) {
      message
    }
  }
`;
export const CREATE_DAY_MUTATION = gql`
  mutation CREATE_DAY_MUTATION($input: DayInput) {
    createDay(input: $input) {
      _id
    }
  }
`;

export const ASSIGN_ROLE_MUTATION = gql`
  mutation ASSIGN_ROLE_MUTATION($input: RoleInput!) {
    assignRole(input: $input) {
      role
      tourId
      userId
      group
      permission
    }
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    signout {
      message
    }
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const REQUEST_MUTATION = gql`
  mutation REQUEST_MUTATION(
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      _id
      email
      name
    }
  }
`;

export const REQUEST_RESET_MUTATION = gql`
  mutation REQUEST_RESET_MUTATION($email: String!) {
    requestReset(email: $email) {
      message
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION($input: UserInput!) {
    createUser(input: $input) {
      _id
      firstName
      lastName
      email
    }
  }
`;
export const REMOVE_USER_MUTATION = gql`
  mutation REMOVE_USER_MUTATION($userId: String!, $tourId: String!) {
    removeUser(userId: $userId, tourId: $tourId) {
      _id
    }
  }
`;
