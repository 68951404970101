import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import SingleTour from '../routes/SingleTour';
import NoRouteFound from './NoRouteFound';

const TourNav = props => {
  return (
    <Router>
      {props.getUpcomingTours.map(tour => (
        <Link
          key={tour._id}
          to={{ pathname: `/tour/${tour._id}`, state: { ...tour } }}
        >
          {tour.title}
        </Link>
      ))}
      <Switch>
        <Route path="/tour/:id" render={SingleTour} />
        <Route component={NoRouteFound} />
      </Switch>
    </Router>
  );
};
export default TourNav;
